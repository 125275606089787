<script setup lang="ts">
import type { FieldMeta } from 'vee-validate';

export interface Props {
    hideError?: boolean;
    hideLabel?: boolean;
    id: string;
    label?: string;
    meta: FieldMeta<any>;
    name?: string;
    required?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    label: '',
    name: '',
    required: false,
});

// For label (excluding required portion) and error message
// White is default, red on invalid/error after touched
const isInvalid = computed(() => props.meta.touched && !props.meta.valid);
</script>

<template>
    <div
        :class="{
            'field-wrap': true,
            invalid: isInvalid,
        }"
    >
        <div v-if="!props.hideLabel" class="content">
            <label :for="props.id">
                {{ props.label }}
                <span
                    class="required"
                    :class="{
                        valid: props.meta.valid,
                        touched: props.meta.touched,
                    }"
                >
                    {{ props.required ? '(required)' : '' }}
                </span>
            </label>
        </div>

        <slot></slot>

        <div v-if="!props.hideError" class="error-wrap">
            <ErrorMessage v-show="name && isInvalid" :name="props.name" />
        </div>
    </div>
</template>

<style lang="postcss" scoped>
.field-wrap {
    width: 100%;
    color: inherit;

    &.invalid {
        color: var(--color-error);
    }

    & .content {
        height: var(--field-wrap-label-height);

        & label {
            font-weight: var(--field-wrap-label-font-weight, normal);
            display: block;
            margin-bottom: 5px;
        }

        & .required {
            color: var(--color-error-not-touched);
            margin-left: 5px;

            &.valid {
                color: var(--color-field-wrap-valid);
            }

            &.touched:not(.valid) {
                color: var(--color-error);
            }
        }
    }

    & .error-wrap {
        min-height: var(--field-wrap-error-height, 2.5rem);
        height: fit-content;
    }
}
</style>
